import {useRef, useEffect, useState, useMemo} from 'react';
import {Form} from '@remix-run/react';

import PencilIcon from '@/components/pages/business-name-generator/icons/PencilIcon';
import InputButton from '@/components/pages/business-name-generator/components/InputButton/InputButton';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import Message from '@/components/base/elements/Message/Message';
import ErrorTriangleIcon from '@/components/pages/business-name-generator/icons/ErrorTriangleIcon';
import CrossIcon from '@/components/pages/business-name-generator/icons/CrossIcon';

import {
  inputWrapperStyles,
  inputStyles,
  pencilIconStyles,
  inputButtonStyles,
  inputIconButtonStyles,
  textAreaStyles,
} from './styles';

interface IDescriptorInputProps {
  id: string;
  label?: string;
  className?: string;
  tags?: number[];
  tagNames?: string[];
  initialValue?: string;
  error?: string;
  maxValueLength?: number;
  placeholder?: string;
  isLoadingResults?: boolean;
}

export const DescriptorInput = ({
  id,
  label,
  className,
  tagNames,
  tags,
  initialValue = '',
  error,
  placeholder,
  isLoadingResults,
}: IDescriptorInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [inputState, setInputState] = useState<
    'error' | 'focused' | 'default'
  >();

  const isMobile = useMediaQuery('(max-width: 1199px)');

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    }
  }, [tags]);

  useEffect(() => {
    if (error) {
      setInputState('error');
    } else if (isFocused) {
      setInputState('focused');
    } else {
      setInputState('default');
    }
  }, [error, isFocused]);

  const isDisabled = value?.length === 0 || value === initialValue;

  const tagsAsStr = tags
    ?.map((i) => {
      return tagNames && tagNames[i];
    })
    .join(',');

  const onCrossClick = (e: any) => {
    if (inputRef.current) {
      setValue('');
      inputRef.current.focus();
      e.preventDefault();
    }
  };

  const sharedProperties = useMemo(() => {
    return {
      name: 'search-query',
      onChange: (e: any) => {
        setValue(e.target.value);
      },
      onFocus: () => {
        setIsFocused(true);
      },
      onBlur: () => {
        setIsFocused(false);
      },
      value: value,
      placeholder: placeholder,
    };
  }, [value, placeholder]);

  return (
    <Form
      className={className}
      method="post"
      data-component-extra-search-value={value}
      data-component-extra-search-tags={tagsAsStr}
      noValidate
    >
      <div
        className={inputWrapperStyles({
          state: isLoadingResults ? 'default' : inputState || 'default',
        })}
      >
        <PencilIcon className={pencilIconStyles()} />
        {isMobile === null ? null : isMobile ? (
          <textarea className={textAreaStyles()} {...sharedProperties} />
        ) : (
          <input
            ref={inputRef}
            className={inputStyles()}
            type="text"
            autoComplete="off"
            spellCheck="false"
            {...sharedProperties}
          />
        )}
        <label className="sr-only" htmlFor={id}>
          {label}
        </label>
        <button
          type="reset"
          onClick={onCrossClick}
          className="absolute top-[40%] right-28 md:block hidden cursor-pointer"
        >
          <CrossIcon />
        </button>
        {tagNames?.map((tag: string, index) => (
          <input
            key={`${index}-hidden-input`}
            type="text"
            value={tag}
            name={`tag_${index}`}
            hidden
            readOnly
          />
        ))}
        <InputButton
          type="submit"
          mode="lavender"
          className={inputButtonStyles({
            state: isLoadingResults ? 'loading' : null,
            isDisabled,
          })}
          disabled={isDisabled}
          iconClassName={inputIconButtonStyles({isDisabled})}
        />
      </div>
      {error && !isLoadingResults && (
        <Message
          className="pt-2"
          children={error}
          state="error"
          errorIcon={<ErrorTriangleIcon className="hidden sm:inline" />}
        />
      )}
    </Form>
  );
};
