import type {VariantProps} from 'class-variance-authority';
import {ViewIcon} from '@shopify/polaris-icons';

import type {BNGButtonState} from '@/components/pages/business-name-generator/types';
import {twMerge} from '@/stylesheets/twMerge';
import ArrowRight from '@/components/brochureV2/icons/ArrowRight';
import {useTranslations} from '@/hooks/useTranslations';

import {
  wrapButtonStyles,
  previewButtonStyles,
  buttonStyles,
  iconStyles,
} from './style';

interface IGeneratedNameButtonProps extends VariantProps<typeof buttonStyles> {
  state?: BNGButtonState;
  generatedName: string;
  preview: string;
  index: number;
  onClick: (index: number) => void;
}

export default function GeneratedNameButton({
  generatedName,
  preview,
  state,
  index,
  onClick,
}: IGeneratedNameButtonProps) {
  const {values} = useTranslations();

  return (
    <>
      <div
        data-component-name="previewButton"
        className={twMerge(wrapButtonStyles({state}))}
        onClick={() => {
          onClick(index);
        }}
      >
        <a
          className={twMerge(buttonStyles({state}))}
          href={`${values.deeplinkFreeTrial}&store_name=${generatedName}`}
        >
          {generatedName}
          <div className={twMerge(previewButtonStyles({state}))}>
            {state === 'visited' && (
              <ViewIcon className="w-[14px] h-[14px] fill-[#746ACA] mr-[7px]" />
            )}
            {preview}
            <ArrowRight className={twMerge(iconStyles({state}))} />
          </div>
        </a>
      </div>
    </>
  );
}
