import {cva} from 'class-variance-authority';

export const containerStyles = cva([
  'max-w-screen-2xl pt-24 sm:pt-[152px] pb-[48px]',
  'text-center text-lavender-10 bg-bng-dark',
]);

export const headerStyles = cva(
  [
    'font-semibold mt-1 leading-none font-polysans',
    'bg-gradient-to-br from-mandarin-30 to-[#865CFF] from-15% bg-clip-text text-transparent',
  ],
  {
    variants: {
      isStartMode: {
        true: 'text-center text-[46px] md:text-[60px] lg:text-[72px] xl:text-[90px] 2xl:text-[110px]',
        false: 'text-lg sm:text-7xl text-left',
      },
    },
  },
);

export const headerWrapperStyles = cva('sm:px-14', {
  variants: {
    isStartOrRestart: {
      true: 'text-center relative',
      false: 'text-left',
    },
  },
});

export const buttonStyles = cva([
  'bg-bng-dark box-content cursor-pointer',
  'text-lavender-10 font-courier uppercase',
  'border-2 border-lavender-30 rounded-full',
  'px-5 py-2 my-[8px] mx-[4px] hover:text-white',
]);
