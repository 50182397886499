import type {ReactNode} from 'react';

import Typography from '@/components/base/elements/Typography/Typography';

import {messageBubbleItemStyle, messageTailStyle} from './style';

const messageBubbleItemTail = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="35"
    viewBox="0 0 34 35"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3258 15.9464C10.0457
      14.4954 9.89903 12.9969 9.89903
      11.4641V0.946289H33.4094V34.9745C27.7163
      34.9745 22.4958 32.9509 18.4279 29.5838C14.3697
      32.2874 7.86216 34.8574 0 33.4279C2.16543 32.4999
      10.5178 26.9316 10.2084 15.7952C10.246 15.8468 10.2851
      15.8972 10.3258 15.9464Z"
    />
  </svg>
);

interface IMessageBubbleItemProps {
  message: string | ReactNode;
  ctaComponent?: ReactNode;
}

export const MessageBubbleItem = ({
  message,
  ctaComponent,
}: IMessageBubbleItemProps) => (
  <div className={messageBubbleItemStyle()}>
    <div className={messageTailStyle()}>{messageBubbleItemTail}</div>
    <Typography as="span" className="z-10 relative">
      {message}
    </Typography>
    {ctaComponent && (
      <p className="z-10 relative text-body-base">{ctaComponent}</p>
    )}
  </div>
);
