import {cva} from 'class-variance-authority';

export const errorTextStyle = cva([
  'leading-8 font-normal text-base text-white text-center font-courier tracking-tight max-w-lg pb-6 pt-0',
  'md:text-2xl md:text-left md:pt-20 md:pr-5',
]);

export const errorBotStyle = cva([
  'pr-0 md:pr-14 w-24 m-auto sm:m-0 sm:w-fit md:block py-0 md:py-10 ',
]);
