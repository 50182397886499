import {useEffect, useRef, useState, useMemo} from 'react';

import {useTranslations} from '@/components/shared/Page/I18N';
import Hero from '@/components/pages/business-name-generator/components/Hero/Hero';
import type {FormSectionProps} from '@/components/pages/business-name-generator/types';
import {DescriptorInput} from '@/components/pages/business-name-generator/components/DescriptorInput';
import {
  isStartMode,
  isSearchMode,
} from '@/components/pages/business-name-generator/utils/utils';
import ErrorSection from '@/components/pages/business-name-generator/components/ErrorSection/ErrorSection';

export default function FormSection({
  state,
  data,
  mode,
  isLoadingResults,
}: FormSectionProps) {
  const MAX_COUNT_OF_REQUESTS_IN_SESSION = 3;
  const [localState, setLocalState] = useState<boolean>(false);
  const {t, images} = useTranslations();
  const ref = useRef<null | HTMLDivElement>(null);
  const amountOfRequestsInState = (state.data && state.data.length) || 0;
  const limitReached =
    amountOfRequestsInState >= MAX_COUNT_OF_REQUESTS_IN_SESSION;

  const Errors = useMemo(
    () => (
      <div>
        <ErrorSection
          {...t('error')}
          image={{
            srcSet: images.errorBot.image,
            alt: t('error.imageAlt'),
          }}
          className="bg-bng-dark"
        />
      </div>
    ),
    [images.errorBot.image, t],
  );

  useEffect(() => {
    if (limitReached && localState && ref) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [ref, limitReached, localState]);

  useEffect(() => {
    setLocalState(false);
  }, [amountOfRequestsInState]);

  return data?.serverError ? (
    Errors
  ) : (
    <>
      {isStartMode(mode) && (
        <Hero
          {...t('conversion')}
          inputValidationError={data?.inputValidationError}
        />
      )}

      {isSearchMode(mode) && (
        <div className="mt-[48px]">
          <DescriptorInput
            id="descriptor-input"
            initialValue={state.latestSearchTerms}
            tagNames={state.activeTags}
            error={data.inputValidationError}
            isLoadingResults={isLoadingResults}
          />
        </div>
      )}

      <div ref={ref}></div>
    </>
  );
}
