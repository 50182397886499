import {cva} from 'class-variance-authority';

export const buttonStyles = cva(['rounded-full'], {
  variants: {
    mode: {
      dragonFruit: [
        'w-full absolute h-[43px] sm:h-[62px] sm:w-fit mt-2 sm:mt-0 top-16 sm:top-[15px] sm:right-[15px]',
        'flex items-center justify-center sm:border-2',
        'bg-gradient-to-br from-[#F6A17966] to-[#865CFF] from-25%',
        'sm:bg-transparent sm:from-transparent sm:to-transparent',
        'sm:hover:shadow-none sm:focus:shadow-none',
      ],
      lavender: ['border-2 bg-transparent'],
    },
    isLoading: {true: '', false: ''},
  },
  compoundVariants: [
    {
      mode: 'dragonFruit',
      isLoading: true,
      className: 'animate-pulse',
    },
  ],
});

export const iconStyles = cva(['fill-black'], {
  variants: {
    mode: {
      dragonFruit: 'sm:w-[58px] w-[39px] h-[58px]',
      lavender: 'transition text-lavender-10',
    },
  },
  defaultVariants: {
    mode: 'dragonFruit',
  },
});

export const buttonTextStyles = cva(
  [
    'items-center',
    'font-courier text-xl sm:text-2xl sm:ml-[20px] mr-6 sm:mr-0',
  ],
  {
    variants: {
      mode: {
        dragonFruit: 'flex',
        lavender: 'hidden',
      },
      isTextVisible: {
        true: 'flex',
        false: 'flex sm:hidden',
      },
    },
  },
);
