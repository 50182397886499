import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import first from 'lodash/first';
import type {Dispatch, SetStateAction} from 'react';
import {useRef} from 'react';

import {MessageBubble} from '@/components/pages/business-name-generator/primitive/MessageBubble/MessageBubble';
import Button from '@/components/pages/business-name-generator/components/Button/Button';
import Link from '@/components/base/elements/Link/Link';
import {loadDataFromSession} from '@/components/pages/business-name-generator/utils/utils';
import {BNG_REQUEST_SESSION_KEY} from '@/constants';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import type {sessionProps} from '@/components/pages/business-name-generator/types';
import {useTranslations} from '@/hooks/useTranslations';

interface LoadMoreProps {
  state: sessionProps;
  setState: Dispatch<SetStateAction<sessionProps>>;
  noUniqValuesLeft: boolean;
}

export default function LoadMoreSection({
  state,
  setState,
  noUniqValuesLeft,
}: LoadMoreProps) {
  const {t} = useTranslations();
  const isMobile = useMediaQuery('(max-width: 899px)');
  const localActiveTags = state.activeTags || [];
  const unusedTags = difference(state.tags, localActiveTags);
  const ref = useRef<null | HTMLDivElement>(null);
  const displayLoadMore = !isEmpty(unusedTags) && !noUniqValuesLeft;

  const startOver = (e: any) => {
    document.forms[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    document.forms[0].querySelector('input')?.focus();
    e.preventDefault();
  };

  const loadMoreClick = () => {
    let session = loadDataFromSession();

    localActiveTags.push(first(unusedTags) as string);

    session.activeTags = localActiveTags;
    sessionStorage.setItem(BNG_REQUEST_SESSION_KEY, JSON.stringify(session));
    state.activeTags = localActiveTags;
    setState(state);

    //I need to make remix navigation able to track events
    let submitButton: HTMLButtonElement | null =
      document.forms[0].querySelector('button[type="submit"]');
    if (submitButton) {
      submitButton.disabled = false;
      submitButton.click();
    }

    if (ref.current && isMobile) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <div className="flex flex-col items-center pt-10 sm:pt-12">
      {displayLoadMore && (
        <>
          <MessageBubble
            className="mb-[16px] lg:mb-[24px]"
            messages={[t('loadMore.stillNotSure')]}
          />
          <Button
            gradientBorder={true}
            className="mb-4 sm:mb-2.5"
            onClick={loadMoreClick}
          >
            {t('loadMore.letsTryMoreNames')}
          </Button>
        </>
      )}
      <Link
        href="?mode=start"
        size="small"
        className="text-lavender-10 text-base underline underline-offset-1"
        onClick={startOver}
      >
        {t('loadMore.letsStartOver')}
      </Link>
      <div ref={ref}></div>
    </div>
  );
}
