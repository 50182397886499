import type {FC} from 'react';
import {useEffect, useState} from 'react';

interface progressBarProps {
  increment?: number;
  startTimer?: boolean;
  refreshRate?: number;
}

export const ProgressBar: FC<progressBarProps> = ({
  increment = 0.02,
  startTimer = true,
  refreshRate = 1,
}) => {
  const stepIncrement = increment;
  const refresh = refreshRate; // ms
  const [step, setStep] = useState(0);

  useEffect(() => {
    const maxReached = step >= 100;
    const timer =
      startTimer &&
      !maxReached &&
      setInterval(() => {
        const nextStepValue = stepIncrement + step;
        setStep(nextStepValue);
      }, refresh);

    if (timer) {
      return () => clearInterval(timer);
    }
  }, [refresh, step, stepIncrement, startTimer]);

  return (
    <div className="fixed top-16 left-0 z-50 w-full bg-[#3A0F71]">
      <div
        className="bg-dragonfruit-30 h-2.5 shadow-[0_0px_20px_3px_#ED6BF8]"
        style={{width: step + '%'}}
      ></div>
    </div>
  );
};
