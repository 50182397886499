import type {ActionFunctionArgs} from '@remix-run/cloudflare';
import {useActionData, useSearchParams} from '@remix-run/react';
import {useEffect, useState} from 'react';
import type {LinksFunction} from '@remix-run/server-runtime';
import isEmpty from 'lodash/isEmpty';

import type {Handle} from '@/types';
import type {
  sessionProps,
  responseProps,
} from '@/components/pages/business-name-generator/types';
import {useTranslations} from '@/components/shared/Page/I18N';
import PageLayout from '@/components/shared/Page/PageLayout';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {ColorSchemeEnum, HeaderBackgroundColorScheme} from '@/enums';
import Header from '@/components/brochureV2/section/Header/Header';
import Outcomes from '@/components/brochureV2/section/Outcomes';
import {SequencedContent} from '@/components/pages/business-name-generator/SequencedContent/SequencedContent';
import BusinessNameGeneratorSection from '@/components/pages/business-name-generator/components/BusinessNameGeneratorSection/BusinessNameGeneratorSection';
import {NameIdeasSlider} from '@/components/pages/business-name-generator/NameIdeasSlider';
import FAQ from '@/components/brochureV2/section/FAQ/FAQ';
import Conversion from '@/components/brochureV2/section/Conversion/Conversion';
import {BNG_REQUEST_SESSION_KEY} from '@/constants';
import {actionLogic} from '@/components/pages/business-name-generator/actionLogic/action';
import {
  uniqValues,
  isStartMode,
  isSearchMode,
  loadDataFromSession,
} from '@/components/pages/business-name-generator/utils/utils';
import {useMediaQuery} from '@/hooks/useMediaQuery';

export const handle: Handle = {};

export {loader} from '@/utils/server/loader/loader';

export const links: LinksFunction = () => {
  return [
    {
      rel: 'preload',
      as: 'font',
      crossOrigin: 'anonymous',
      type: 'font/ttf',
      href: `https://cdn.shopify.com/static/fonts/business-name-generator/PathwayGothicOne-Regular.ttf`,
    },
  ];
};
const amountToStore = {mobile: 6, desktop: 24};

export async function action(args: ActionFunctionArgs) {
  return await actionLogic(args);
}

export default function ToolsBusinessNameSearch() {
  const [state, setState] = useState<sessionProps>({});
  let [searchParams, setSearchParams] = useSearchParams();
  let mode = searchParams.get('mode') || 'start';
  const {t, images} = useTranslations();
  const isMobile = useMediaQuery('(max-width: 899px)');
  const data = useActionData<any>();

  useEffect(() => {
    let session = loadDataFromSession();

    if (!isEmpty(data?.generatedNames)) {
      const newSearch = data.originalSearchTerms !== session.latestSearchTerms;
      const firstKey = isMobile ? 'mobile' : 'desktop';
      const requestData: responseProps = {
        searchTerms: data.searchTerms,
        generatedNames: uniqValues(
          data.generatedNames,
          session.data,
          amountToStore[firstKey],
          newSearch,
        ),
      };

      // clear session if we transition to search mode from any mode
      if (isStartMode(mode) || newSearch) {
        setSearchParams('mode=search');
        session.data = [requestData];
        session.activeTags = [];
      } else {
        session.data?.push(requestData);
      }

      session.tags = data.tags;
      session.latestSearchTerms = data.originalSearchTerms;
      sessionStorage.setItem(BNG_REQUEST_SESSION_KEY, JSON.stringify(session));
    }
    setState(session);

    if (isSearchMode(mode) && isEmpty(session.data)) {
      setSearchParams('mode=start');
    }

    // redirect to landing page is mode is unexpected
    if (!['start', 'search', 'restart'].includes(mode)) {
      setSearchParams('mode=start');
    }
  }, [data, isMobile, mode, setSearchParams]);

  const transformedBngData = {
    serverError: data?.error,
    inputValidationError:
      data?.inputValidationError &&
      t(`conversion.inputValidationError.${data.inputValidationError}`),
  };

  return (
    <PageLayout
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
      header={(props) => (
        <Header
          {...props}
          background={HeaderBackgroundColorScheme.TransparentDark}
          className="border-b border-b-white/10"
        />
      )}
      isPureTitle
    >
      <BusinessNameGeneratorSection
        data={transformedBngData}
        state={state}
        setState={setState}
        mode={mode}
      />
      <Outcomes
        {...t('outcomes')}
        colorScheme={ColorSchemeEnum.Dark}
        className="max-w-screen-2xl"
        blocks={t('outcomes.blocks').map((item: any, idx: number) => ({
          ...item,
          icon: {
            src: images.outcomes.icons.en[idx],
          },
        }))}
      />
      <SequencedContent {...t('sequencedContent')} className="sm:grid" />
      <NameIdeasSlider {...t('nameIdeas')} />
      <FAQ
        {...t('faq1')}
        colorScheme={ColorSchemeEnum.Dark}
        className="sm:block"
      />
      <Conversion
        {...t('conversion1')}
        colorScheme={ColorSchemeEnum.ShopifyPurpleGradient}
        cta={{...t('conversion1')}}
        className="sm:block"
        headingClass="!mb-0"
        subheadClass="!mb-6 mt-[-8px]"
      />
    </PageLayout>
  );
}
