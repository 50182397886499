import difference from 'lodash/difference';
import forEach from 'lodash/forEach';
import take from 'lodash/take';

import {BNG_REQUEST_SESSION_KEY} from '@/constants';
import type {
  sessionProps,
  responseProps,
} from '@/components/pages/business-name-generator/types';

export function loadDataFromSession() {
  let session: sessionProps = {};
  let previousRequests = sessionStorage.getItem(BNG_REQUEST_SESSION_KEY);
  if (previousRequests) {
    session = JSON.parse(previousRequests);
  }
  return session;
}

export function isSearchMode(mode: string) {
  return mode === 'search';
}

export function isStartMode(mode: string) {
  return mode === 'start';
}

export function isRestartMode(mode: string) {
  return mode === 'restart';
}

export function uniqValues(
  newValues: string[],
  existingValues: responseProps[] | undefined,
  amountToStore: number,
  newSearch: boolean,
) {
  let uniqVal = newValues;

  if (!newSearch) {
    forEach(existingValues, (obj) => {
      uniqVal = difference(uniqVal, obj.generatedNames);
    });
  }
  return take(uniqVal, amountToStore);
}

export const addTag = (tags: number[], value: number) => {
  const maxAmountOfTags = 2;
  // we unselect tag if user click on it again
  if (tags.includes(value)) {
    tags.splice(tags.indexOf(value), 1);
    return tags;
  }
  // we remove old tag if there are already max amount of them
  tags.length === maxAmountOfTags && tags.shift();

  tags.push(value);
  return tags;
};
