import type {InputHTMLAttributes, RefObject} from 'react';
import {useEffect, useState} from 'react';
import {Form, useNavigation} from '@remix-run/react';

import {twMerge} from '@/stylesheets/twMerge';
import type {BNGmode} from '@/components/pages/business-name-generator/types';
import Message from '@/components/base/elements/Message/Message';
import InputButton from '@/components/pages/business-name-generator/components/InputButton/InputButton';

import ErrorTriangleIcon from '../../icons/ErrorTriangleIcon';
import PencilIcon from '../../icons/PencilIcon';

import {
  inputStyles,
  containerStyles,
  pencilIconStyles,
  inputButtonStyles,
  inputIconButtonStyles,
} from './style';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  id: string;
  label?: string;
  state?: 'error' | 'disabled' | undefined;
  message?: string;
  mode?: BNGmode;
  buttonText?: string | undefined;
  clearTags?: (index: number) => void;
  value?: string;
  placeholder?: string;
  placeholderMobile?: string;
  onBlur?: () => void;
  inputRef?: RefObject<HTMLInputElement>;
}

export default function Input({
  className,
  id,
  label,
  placeholder,
  placeholderMobile,
  state,
  message,
  clearTags,
  value,
  mode = 'dragonFruit',
  onBlur,
  inputRef,
  ...inputProps
}: InputProps) {
  const [isValuePresent, setValuePresent] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const {state: loadingState} = useNavigation();

  const isLoading = loadingState !== 'idle';
  const stateTextId = `${id}-${state || 'description'}`;
  const isErrorMessageVisible = state === 'error';
  const isDisabled = state === 'disabled';
  const isLavender = mode === 'lavender';

  useEffect(() => {
    value && setInputValue(value);
    setValuePresent(Boolean(value));
  }, [value]);

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
    setValuePresent(Boolean(event.target.value));
  };

  const lavender = (
    <>
      <PencilIcon className={pencilIconStyles()} />
      {!isDisabled && (
        <InputButton
          type="submit"
          mode={mode}
          isValuePresent={isValuePresent}
          buttonText={inputProps.buttonText}
          className="absolute top-3/4 sm:top-1/2 -translate-y-1/2 right-2 sm:right-6"
          iconClassName="lg:w-[60px] lg:h-[60px] w-[36px] h-[36px]"
        />
      )}
    </>
  );

  return (
    <Form
      method="post"
      noValidate
      data-component-extra-search-value={inputValue}
    >
      <div className={containerStyles[mode]({state})} data-mode={mode}>
        <input
          className={twMerge(
            inputStyles[mode]({state, isMobile: true}),
            className,
          )}
          placeholder={placeholderMobile}
          type="text"
          name="search-query"
          value={inputValue}
          spellCheck="false"
          onChange={handleChange}
          disabled={isDisabled}
          autoComplete="off"
          onBlur={onBlur}
          ref={inputRef}
        />
        <input
          className={twMerge(
            inputStyles[mode]({state, isMobile: false}),
            className,
          )}
          placeholder={placeholder}
          type="text"
          name="search-query"
          value={inputValue}
          spellCheck="false"
          onChange={handleChange}
          disabled={isDisabled}
          autoComplete="off"
          onBlur={onBlur}
          ref={inputRef}
        />
        <label className="sr-only" htmlFor={id}>
          {label}
        </label>
        {isLavender && !isDisabled && lavender}
        {!isLavender && (
          <InputButton
            type="submit"
            mode={mode}
            isValuePresent={isValuePresent}
            buttonText={inputProps.buttonText}
            disabled={!inputValue}
            className={inputButtonStyles({isDisabled: !inputValue})}
            iconClassName={inputIconButtonStyles({isDisabled: !inputValue})}
          />
        )}
      </div>
      {!isLoading && (
        <Message
          className="sm:pl-6 pt-6 text-xs text-left"
          id={stateTextId}
          children={message && isErrorMessageVisible ? message : undefined}
          state={state}
          errorIcon={<ErrorTriangleIcon className="inline" />}
        />
      )}
    </Form>
  );
}
