import {useEffect, useState, useRef, useCallback, useMemo} from 'react';
import cn from 'classnames';

import Typography from '@/components/base/elements/Typography/Typography';
import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';

import {RenderContentByType} from './RenderContentByType';
import type {ISequencedContentProps} from './types';

export const SequencedContent = ({
  content,
  navIdPrefix,
  className,
}: ISequencedContentProps) => {
  const itemsRef = useRef<any>([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', onWindowScroll);
    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const navIdArray = useMemo(() => {
    return content.data.map((section) => {
      return section.title.split(' ').join('_');
    });
  }, [content]);

  const onWindowScroll = useCallback(() => {
    const activeSectionOffset = 5;
    const currentOffset = window.scrollY;
    const lastIndex = itemsRef.current.length - 1;

    if (currentOffset < itemsRef.current[0]) {
      setActiveIndex(0);
    } else if (currentOffset > itemsRef.current[lastIndex]) {
      setActiveIndex(lastIndex);
    } else {
      for (let i = 0; i <= itemsRef.current.length; i++) {
        if (currentOffset + activeSectionOffset >= itemsRef.current[i]) {
          setActiveIndex(i);
        } else {
          break;
        }
      }
    }
  }, []);

  return (
    <Grid className={className}>
      <Col className="nav-drawer" span={{xs: 12, md: 5}}>
        <nav className="top-legacy-global-header sticky py-0 pt-10 md:py-20">
          <div className="font-bold mb-8 uppercase">{content.hint}</div>
          <ul>
            {content.data.map((contentSection, index) => (
              <li
                key={`content-nav-item-${index}`}
                className={cn(
                  'py-[7px] pl-[9px] border-l border-[#CAD4D7]',
                  'first:pt-4 last:pb-4',
                )}
              >
                <a
                  className={cn(
                    'text-body-base inline-block px-[12px] py-[9px] relative',
                    'border rounded-[4px]',
                    {'border-transparent': index !== activeIndex},
                    {'bg-[#F6F8FA] border-[#E5EBF0]': index === activeIndex},
                  )}
                  href={
                    navIdPrefix
                      ? `#${navIdPrefix}-${index}`
                      : `#${navIdArray[index]}`
                  }
                >
                  {index === activeIndex && (
                    <div
                      className={cn(
                        'absolute top-0 bottom-0 left-[-11px]',
                        'border-[3px] border-l-[#586572]',
                        'border-transparent',
                      )}
                    />
                  )}
                  {contentSection.title}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </Col>
      <Col span={{xs: 12, md: 7}}>
        <div className="pb-20">
          {content.data.map((contentSection, sectionIndex) => (
            <div
              key={`content-section-${sectionIndex}`}
              id={
                navIdPrefix
                  ? `${navIdPrefix}-${sectionIndex}`
                  : navIdArray[sectionIndex]
              }
              className="content-item pt-16 md:first:pt-20 first:pt-0"
              ref={(el) => (itemsRef.current[sectionIndex] = el?.offsetTop)}
            >
              <Typography as="h2" size="t2" className="mb-6">
                {contentSection.title}
              </Typography>
              <div>
                {contentSection.content.map((contentSectionData, index) => (
                  <RenderContentByType
                    contentSectionData={contentSectionData}
                    key={`content-section-${sectionIndex}-${index}`}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </Col>
    </Grid>
  );
};
