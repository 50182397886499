import {useMediaQuery} from '@/hooks/useMediaQuery';
import {
  headerStyles,
  headerWrapperStyles,
} from '@/components/pages/business-name-generator/components/Hero/style';
import SparkleIcon from '@/components/pages/business-name-generator/icons/SparkleIcon';
import {
  isRestartMode,
  isStartMode,
} from '@/components/pages/business-name-generator/utils/utils';

interface HeaderProps {
  tools: string;
  header: string;
  subHeader: string;
  mode: string;
}

export default function Header({tools, header, subHeader, mode}: HeaderProps) {
  const isMobile = useMediaQuery('(max-width: 749px)');

  const isStart = isStartMode(mode);
  const isRestart = isRestartMode(mode);
  const showFreeTools = isRestart || isStart || !isMobile;

  return (
    <>
      <div
        className={headerWrapperStyles({
          isStartOrRestart: isStart || isRestart,
        })}
      >
        {showFreeTools && (
          <a href="/tools" className="sm:text-xl uppercase font-bold">
            {tools}
          </a>
        )}
        <h1
          className={headerStyles({
            isStartMode: isStart || isRestart,
          })}
        >
          <a href="?index">{header}</a>
        </h1>
        {isStart && (
          <>
            <div>
              <p className="text-lg sm:text-[22px] sm:mb-20 mb-14 mt-2 font-normal sm:px-20">
                {subHeader}
              </p>
            </div>
            <SparkleIcon
              className="top-10 right-0 absolute hidden sm:block"
              width={55}
              height={87}
              fill
            />
          </>
        )}
      </div>
    </>
  );
}
