interface SparkleProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: boolean;
}

export default function SparkleIcon({
  className,
  width = 19,
  height = 21,
  fill,
}: SparkleProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 21"
      fill={fill ? 'url(#grad1)' : 'currentColor'}
      stroke={fill ? 'url(#grad1)' : 'none'}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" style={{stopColor: '#F6A179'}} />
          <stop offset="100%" style={{stopColor: '#865CFF'}} />
        </linearGradient>
      </defs>
      <path d="M13.2702 2.36884C12.6415 1.82313 12.7239 0.476562 12.4941 0.476562C12.2643 0.476562 12.4161 1.57507 11.8525 2.24481C11.3124 2.88663 9.95353 2.8614 10.0012 2.9677C10.0756 3.13345 11.3756 3.05629 11.9262 3.81462C12.4176 4.49145 12.26 5.47656 12.5028 5.47656C12.7456 5.47656 12.5539 4.38159 13.1921 3.66579C13.7261 3.06692 15 3.15197 15 2.9677C15 2.85431 13.8376 2.8614 13.2702 2.36884Z" />
      <path d="M15.8863 14.2611C14.7547 13.1697 14.903 10.4766 14.4894 10.4766C14.0758 10.4766 14.3489 12.6736 13.3344 14.0131C12.3622 15.2967 9.91636 15.2462 10.0022 15.4588C10.136 15.7903 12.476 15.636 13.4671 17.1527C14.3517 18.5063 14.068 20.4766 14.505 20.4766C14.942 20.4766 14.5969 18.2866 15.7458 16.855C16.707 15.6573 19 15.8274 19 15.4588C19 15.2321 16.9076 15.2462 15.8863 14.2611Z" />
      <path d="M7.84838 8.39648C6.33964 6.97763 6.53734 3.47656 5.98587 3.47656C5.43439 3.47656 5.79857 6.33269 4.44592 8.07401C3.14965 9.74274 -0.111519 9.67713 0.00293717 9.95353C0.181391 10.3845 3.30135 10.1839 4.6228 12.1555C5.80222 13.9153 5.42399 16.4766 6.00668 16.4766C6.58936 16.4766 6.12925 13.6296 7.66109 11.7686C8.94268 10.2115 12 10.4326 12 9.95353C12 9.6587 9.21017 9.67713 7.84838 8.39648Z" />
    </svg>
  );
}
