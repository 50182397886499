import {createRef, useState} from 'react';
import {Form, useNavigation} from '@remix-run/react';

import {useTranslations} from '@/components/shared/Page/I18N';
import {MessageBubble} from '@/components/pages/business-name-generator/primitive/MessageBubble/MessageBubble';
import Typography from '@/components/base/elements/Typography/Typography';

import type {InputProps} from '../Input/Input';
import Input from '../Input/Input';

import {buttonStyles} from './style';

interface QuickSearchItem {
  text: string;
  href: string;
  name: string;
}
interface HeroProps {
  bubbleMessages: string[];
  mobileBubbleMessages: string[];
  quickSearchItems: QuickSearchItem[];
  quickSearchHeading: string;
  className?: string;
  input: InputProps;
  kicker: string;
  loadingHeading: string;
  inputValidationError?: string;
}
export default function Hero({
  className,
  bubbleMessages,
  mobileBubbleMessages,
  input,
  quickSearchItems,
  quickSearchHeading,
  kicker,
  loadingHeading,
  inputValidationError,
}: HeroProps) {
  const {t} = useTranslations();
  const {state} = useNavigation();
  const [quickSearchInput, setQuickSearchInput] = useState<string | undefined>(
    undefined,
  );
  const heroInputRef = createRef<HTMLInputElement>();

  const isLoading = state !== 'idle';
  const errorState = inputValidationError ? 'error' : undefined;
  const errorMessage = inputValidationError;
  const inputState = isLoading ? 'disabled' : errorState || undefined;

  const centerOnInput = () => {
    heroInputRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  return (
    <div className={className}>
      <div className="container">
        <MessageBubble
          messages={bubbleMessages}
          className="hidden sm:flex sm:mb-9"
        />
        <MessageBubble
          messages={mobileBubbleMessages}
          className="sm:hidden mb-4"
        />
        <Input
          {...input}
          message={errorMessage}
          state={inputState}
          value={quickSearchInput}
          inputRef={heroInputRef}
          data-component-name="hero-search-term"
        />
        {isLoading && (
          <div className="hidden sm:block mt-10 xs:mt-24 sm:mt-[20px] pb-20 sm:pb-16 relative">
            <div className="absolute text-xs sm:text-[20px] text-white right-0 top-1/2 -translate-y-1/2 w-full mb-10 text-center">
              {loadingHeading}
            </div>
          </div>
        )}
        <div className="hidden sm:block pb-[52px] mt-14">
          <div className="text-lg mb-4">{quickSearchHeading}</div>
          <Form
            method="post"
            noValidate
            className="pb-3.5"
            name="bng-quick-search"
          >
            {quickSearchItems.map((searchItem, index) => (
              <input
                key={`${index}-quick-search`}
                className={buttonStyles()}
                value={searchItem.text}
                data-component-extra-search-value={searchItem.text}
                type="submit"
                onClick={() => {
                  setQuickSearchInput(searchItem.text);
                  centerOnInput();
                }}
                name="search-query"
                data-component-name="bng-quick-search"
              />
            ))}
          </Form>
          <div className="text-[14px] text-center text-lavender-10 pt-[2px]">
            {t('conversion.inputField.text')}
            <div
              className="inline underline text-bold cursor-pointer"
              onClick={() => {
                centerOnInput();
                heroInputRef?.current?.focus();
              }}
            >
              {t('conversion.inputField.button')}
            </div>
          </div>
        </div>
        <Typography className="text-[13px] pt-[20px] sm:pt-0 mt-[24px] text-[rgba(255,_255,_255,_0.7)]">
          {kicker}
        </Typography>
      </div>
    </div>
  );
}
