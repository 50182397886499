import {useCallback} from 'react';
import {useLocation} from '@remix-run/react';

import Link from '@/components/base/elements/Link/Link';

export interface INameIdeasBlockProps {
  title: string;
  namesList: {text: string; url: string}[];
}

export const NameIdeasSection = ({title, namesList}: INameIdeasBlockProps) => {
  const location = useLocation();

  const formatHrefFromName = useCallback(
    (name: string) => {
      const currentLocation = location.pathname;
      const path = name.toLowerCase().replaceAll(' ', '-');

      return `${currentLocation}/${path}`;
    },
    [location],
  );

  return (
    <div className="border rounded-[18px] border-[#ccc] p-lg h-full">
      <div className="font-bold mb-sm text-t6">{title}</div>
      <div className="flex flex-col items-baseline">
        {namesList.map((name, index) => (
          <Link
            key={index}
            href={
              `${location.pathname}${name.url}` || formatHrefFromName(name.text)
            }
            arrow={false}
            className="mb-xs last:mb-0 duration-0"
          >
            {name.text}
          </Link>
        ))}
      </div>
    </div>
  );
};
