import {useEffect, useState} from 'react';

import AutoGrid, {
  THRESHOLD_OPTIONS,
} from '@/components/brochureV2/layout/AutoGrid/AutoGrid';
import {BNG_REQUEST_SESSION_KEY} from '@/constants';
import type {
  sessionProps,
  IGeneratedNamesListProps,
} from '@/components/pages/business-name-generator/types';
import {loadDataFromSession} from '@/components/pages/business-name-generator/utils/utils';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {twMerge} from '@/stylesheets/twMerge';

import GeneratedNameButton from './GeneratedNameButton';

const LIMITS = {
  mobile: {
    maxNames: 6,
    columns: 1,
  },
  desktop: {
    maxNames: 24,
    columns: 4,
  },
};

export default function GeneratedNamesList({
  generatedNames,
  visited,
  searchTerms,
  preview,
}: IGeneratedNamesListProps) {
  const isMobile = useMediaQuery('(max-width: 899px)');
  const limitKey = isMobile ? 'mobile' : 'desktop';
  const [visitedNames, setVisited] = useState<number[]>([]);
  const initialNamesList = generatedNames.slice(0, LIMITS[limitKey].maxNames);

  const findInSession = (terms: string, session: sessionProps) =>
    session.data?.find((e: {searchTerms: string}) => e.searchTerms === terms);

  useEffect(() => {
    visited && setVisited(visited);
  }, [visited]);

  const handleClick = (indexInt: number) => {
    if (!visitedNames.includes(indexInt)) {
      const value = visitedNames.concat([indexInt]);
      let session = loadDataFromSession();
      let currentSection = findInSession(searchTerms, session);
      currentSection && (currentSection.visited = value);
      setVisited(value);
      sessionStorage.setItem(BNG_REQUEST_SESSION_KEY, JSON.stringify(session));
    }
  };
  const nameButton = (i: number, name: string) => (
    <GeneratedNameButton
      index={i}
      key={`${name}-${i}`}
      generatedName={name}
      preview={preview}
      state={visitedNames && visitedNames.includes(i) ? 'visited' : 'default'}
      onClick={handleClick}
    />
  );

  return (
    <div>
      <AutoGrid
        threshold={THRESHOLD_OPTIONS.find(
          (e) => e === LIMITS[limitKey].columns,
        )}
        className={twMerge(
          'grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-x-[24px] gap-y-[24px] md:gap-y-[16px] generatedNameButtons',
        )}
      >
        {initialNamesList.map((name, i) => nameButton(i, name))}
      </AutoGrid>
    </div>
  );
}
