function PencilIcon({className}: {className?: string}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M15.232 5.23233L18.768 8.76833M16.732 3.73233C17.2009 3.26343 17.8369 3 18.5 3C19.1631 3 19.7991 3.26343 20.268 3.73233C20.7369 4.20123 21.0003 4.8372 21.0003 5.50033C21.0003 6.16346 20.7369 6.79943 20.268 7.26833L6.5 21.0363H3V17.4643L16.732 3.73233Z"
        stroke="#CEC9F8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PencilIcon;
