import {cva} from 'class-variance-authority';

export const inputWrapperStyles = cva(
  [
    'relative flex item-center h-[100px] lg:h-[88px] p-2 lg:p-0 lg:pr-[100px]',
    'rounded-[8px] bg-[#312A56] border-2',
    'lg:flex-row',
    'flex-col',
  ],
  {
    variants: {
      state: {
        default: ['border-lavender-30'],
        focused: ['border-lavender-10'],
        error: ['border-[#FF6669]'],
      },
    },
  },
);

export const inputStyles = cva([
  'font-courier text-lavender-10 text-[20px] h-full w-full',
  'pl-[60px] outline-0',
  'border-transparent bg-transparent',
  'placeholder:text-lavender-10 placeholder:font-thin',
  'hover:text-white hover:placeholder:text-white duration-150',
  'focus:text-white focus:placeholder:text-transparent',
]);

export const textAreaStyles = cva([
  'resize-none outline-none border-transparent bg-transparent',
  'h-full mb-[12px] ml-[24px]',
  'font-courier text-[16px] text-lavender-10',
  'focus:placeholder:text-transparent focus:text-white duration-150',
]);

export const pencilIconStyles = cva([
  'absolute lg:top-1/2 lg:left-4 lg:-translate-y-1/2 lg:w-[24px] lg:h-[24px]',
  'w-[16px] h-[16px] top-[8px] height-[8px]',
]);

export const inputButtonStyles = cva(
  [
    'absolute lg:top-1/2 lg:-translate-y-1/2 lg:right-[24px]',
    'bottom-[8px] right-[8px]',
    'lg:w-[63px] lg:h-[63px]',
    'w-[40px] h-[40px]',
  ],
  {
    variants: {
      state: {
        loading: ['animate-pulse'],
      },
      isDisabled: {true: 'border-[#6f6a89]', false: 'border-white'},
    },
  },
);

export const inputIconButtonStyles = cva(
  ['lg:w-[60px] lg:h-[60px]', 'w-[36px] h-[36px]', 'transition-all'],
  {
    variants: {
      isDisabled: {
        true: '-rotate-90 stroke-[#6f6a89]',
        false: 'rotate-0 stroke-white',
      },
    },
  },
);
