import {useState, useMemo} from 'react';
import cn from 'classnames';

import CarouselButton from '@/components/brochureV2/primitive/SpecialtyButtons/CarouselButton/CarouselButton';
import {AlignmentEnum} from '@/enums';
import {useTranslations} from '@/components/shared/Page/I18N';

import type {INameIdeasBlockProps} from './NameIdeasSection';
import {NameIdeasBox} from './NameIdeasBox';

interface INameIdeasSliderProps {
  title?: string;
  titleSecondary?: string;
  nameIdeasData: INameIdeasBlockProps[];
  slideSize?: number;
}

export const NameIdeasSlider = ({
  title,
  titleSecondary,
  nameIdeasData,
  slideSize = 4,
}: INameIdeasSliderProps) => {
  const {t} = useTranslations();
  const [activeIndex, setActiveIndex] = useState(0);

  const namesData = useMemo(() => {
    const formattedNamesData = [];
    for (let i = 0; i < nameIdeasData.length; i += slideSize) {
      formattedNamesData.push(nameIdeasData.slice(i, i + slideSize));
    }
    return formattedNamesData;
  }, [nameIdeasData, slideSize]);

  return (
    <div className="container py-3xl hidden sm:block">
      {title && <div className="w-3/6 font-bold text-t2 mb-2xl">{title}</div>}
      <div className="flex justify-between items-center mb-md">
        {titleSecondary && (
          <span className="font-bold text-t5">{titleSecondary}</span>
        )}
        <div className="space-x-1 md:space-x-4 flex flex-row flex-nowrap">
          <CarouselButton
            aria-label={t('global:ariaLabels.previous')}
            componentName="carousel-previous"
            direction={AlignmentEnum.Left}
            disabled={activeIndex === 0}
            onClick={() =>
              setActiveIndex(
                activeIndex === 0 ? namesData?.length - 1 : activeIndex - 1,
              )
            }
          />
          <CarouselButton
            aria-label={t('global:ariaLabels.next')}
            componentName="carousel-next"
            direction={AlignmentEnum.Right}
            disabled={activeIndex === namesData?.length - 1}
            onClick={() => setActiveIndex(activeIndex + 1)}
          />
        </div>
      </div>
      {namesData?.map((item, index) => (
        <div
          key={`${item[0].title}-${index}`}
          className={cn('block', {
            'hidden flex-wrap': activeIndex !== index,
          })}
        >
          {item?.length && <NameIdeasBox namesIdeas={item} />}
        </div>
      ))}
    </div>
  );
};
