import {useMemo} from 'react';
import {Trans} from 'react-i18next';

import Section from '@/components/base/layouts/Section/Section';
import type {IGeneratedNamesListProps} from '@/components/pages/business-name-generator/types';
import {MessageBubble} from '@/components/pages/business-name-generator/primitive/MessageBubble/MessageBubble';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {useContextualCTAOnBNG} from '@/hooks/experiments/useContextualCTAOnBNG';
import {useTranslations} from '@/hooks/useTranslations';

import GeneratedNamesList from './GeneratedNamesList';

export default function GeneratedNames(props: IGeneratedNamesListProps) {
  const isMobile = useMediaQuery('(max-width: 899px)');
  const text = props.index > 0 ? props.secondaryTitle : props.initialTitle;
  const isContextualCTA = useContextualCTAOnBNG();
  const {values} = useTranslations();

  const contextualCTAText = useMemo(
    () => (
      <Trans
        i18nKey="generatedNames.contextualCTA"
        ns="pages/tools/business-name-generator/content"
        components={{
          url: (
            <a
              href={values.deeplinkFreeTrial}
              className="!text-white !font-normal"
            />
          ),
        }}
      />
    ),
    [values],
  );

  const ctaComponent =
    isContextualCTA && props.index === 0 ? contextualCTAText : undefined;

  const mobileHeader = useMemo(() => {
    return props.index === 0 ? (
      <MessageBubble
        className="pb-8 sm:pb-6"
        messages={[text]}
        ctaComponent={ctaComponent}
      />
    ) : (
      <div className="my-[32px] mx-[16px] md:m-5 h-[1px] bg-gradient-to-br from-[#F6A179] to-[#865CFF] from-25%" />
    );
  }, [ctaComponent, props.index, text]);

  return (
    <Section
      className="gap-0 sm:pt-12 bg-bng-dark py-0 generatedNameButtons"
      sectionName="generatedNames"
    >
      {isMobile ? (
        mobileHeader
      ) : (
        <MessageBubble
          className="sm:pb-6"
          messages={[text]}
          ctaComponent={ctaComponent}
        />
      )}
      <GeneratedNamesList {...props} />
    </Section>
  );
}
