function ErrorTriangleIcon({className}: {className?: string}) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      className="inline-block mr-1"
    >
      <path
        className={className}
        fill="#FF6669"
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8809 2.92834C12.5036 2.22457 11.4974 2.22424 11.1197 2.92834L2.13061 19.9685C1.75397 20.6375 2.23727 21.4671 3.00056 21.4671H20.9994C21.7627 21.4671 22.246 20.6375 21.8694 19.9685L12.8809 2.92834ZM11.0001 9.42478C11.0001 8.86953 11.4467 8.42131 12 8.42131C12.2766 8.42131 12.5266 8.53504 12.7066 8.71566C12.8866 8.89629 12.9999 9.14715 12.9999 9.42478V13.7741C12.9999 14.3294 12.5533 14.7776 12 14.7776C11.4467 14.7776 11.0001 14.3294 11.0001 13.7741V9.42478ZM12 19.4605C11.2634 19.4605 10.6667 18.8617 10.6667 18.1225C10.6667 17.3833 11.2634 16.7845 12 16.7845C12.7366 16.7845 13.3332 17.3833 13.3332 18.1225C13.3332 18.8614 12.7366 19.4605 12 19.4605Z"
      />
    </svg>
  );
}

export default ErrorTriangleIcon;
