import {cva} from 'class-variance-authority';

export const messageBubbleStyle = cva([
  'flex flex-col items-baseline pl-[10px] text-left',
]);

export const messageBubbleItemStyle = cva([
  'inline-block relative',
  'background-[#352A70] rounded-[24px] py-[16px] px-4 sm:pl-7 sm:pr-9 mb-[24px]',
  'text-white text-[14px] sm:text-body-base',
  'last:mb-0',
]);

export const messageTailStyle = cva([
  'absolute bottom-0 left-[-8px] fill-[#352A70]',
]);
