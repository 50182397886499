import {cva} from 'class-variance-authority';

export const buttonStyles = cva(
  [
    'text-lg font-bold font-sans',
    'rounded-full duration-150 transition-colors',
  ],
  {
    variants: {
      filled: {
        true: ['py-3.5 px-7 b-1', 'hover:text-lavender-90'],
        false: ['p-0.5 text-lavender-10', 'hover:text-white'],
      },
      gradientBorder: {
        true: [
          'bg-gradient-to-br from-mandarin-30 to-[#865CFF] from-25%',
          'hover:text-white',
          'hover:shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)_inset,_0px_0px_30px_3px_#FF36D1]',
          'focus:shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)_inset,_0px_0px_30px_3px_#FF36D1]',
        ],
        false: ['bg-lavender-30'],
      },
    },
  },
);

export const spanStyles = cva('block bg-bng-dark rounded-full py-3.5 px-6');
