import AutoGrid from '@/components/brochureV2/layout/AutoGrid/AutoGrid';

import type {INameIdeasBlockProps} from './NameIdeasSection';
import {NameIdeasSection} from './NameIdeasSection';

interface INameIdeasContainerProps {
  namesIdeas: INameIdeasBlockProps[];
}

export const NameIdeasBox = ({namesIdeas}: INameIdeasContainerProps) => (
  <AutoGrid>
    {namesIdeas &&
      namesIdeas.map(({title, namesList}, index) => (
        <NameIdeasSection key={index} title={title} namesList={namesList} />
      ))}
  </AutoGrid>
);
