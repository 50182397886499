import {cva} from 'class-variance-authority';

export const containerStyles = {
  dragonFruit: cva(
    [
      'flex relative rounded-full p-[3px] bg-gradient-to-br from-mandarin-30 to-[#865CFF] from-25% duration-150',
      'mb-[60px] sm:mb-0',
    ],
    {
      variants: {
        state: {
          error: 'bg-state-error-light bg-none',
          disabled: '',
        },
      },
    },
  ),
  lavender: cva(['relative']),
};

export const inputStyles = {
  dragonFruit: cva(
    [
      'w-full px-8 sm:pr-60 text-white text-[16px] sm:text-2xl h-[60px] sm:h-[86px]',
      'placeholder:italic placeholder:text-white placeholder:font-light',
      'focus:outline-none focus:placeholder:opacity-0',
      'sm:hover:shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)_inset,_0px_0px_30px_3px_#FF36D1]',
      'focus:shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)_inset,_0px_0px_30px_3px_#FF36D1]',
    ],
    {
      variants: {
        state: {
          error: 'ring-state-error-dark',
          disabled: '',
        },
        isMobile: {
          true: [
            'md:hidden',
            'rounded-full bg-gradient-to-br from-[#3A1C41] to-[#2C1451]',
          ],
          false: [
            'hidden md:block',
            'rounded-full bg-gradient-to-br from-[#73434f] to-[#4f2d7a]',
          ],
        },
      },
    },
  ),
  lavender: cva(
    [
      'w-full h-[120px] sm:h-[88px] pb-20 sm:pb-0 pl-6 sm:pl-10',
      'text-lavender-10 text-sm sm:text-xl',
      'rounded-[8px] bg-[#312A56] border-2 border-lavender-30',
      'placeholder:text-lavender-10 placeholder:font-thin',
      'hover:text-white hover:placeholder:text-white hover:border-lavender-10 duration-150',
      'focus:text-white focus:placeholder:text-white focus:border-lavender-10',
    ],
    {
      variants: {
        state: {
          error: 'border-state-error-light',
          disabled:
            'border-0 hover:border-0 hover:placeholder:text-lavender-10',
        },
      },
    },
  ),
};

export const pencilIconStyles = cva([
  'absolute top-5 sm:top-1/2 left-2 sm:left-4 -translate-y-1/2',
  'w-3 h-3 sm:w-6 sm:h-6',
]);

export const inputButtonStyles = cva([], {
  variants: {
    isDisabled: {
      true: 'border-[#9582ae] opacity-25 sm:opacity-100',
      false:
        'border-white focus:shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)_inset,_0px_0px_30px_3px_#FF36D1]',
    },
  },
});

export const inputIconButtonStyles = cva([], {
  variants: {
    isDisabled: {
      true: 'stroke-[#9582ae]',
      false: 'stroke-white',
    },
  },
});
