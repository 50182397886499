function ArrowRight({className}: {className?: string}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="63"
      viewBox="0 0 63 63"
      fill="currentColor"
    >
      <path
        d="M14.2012 31.7422L46.4586 31.6133"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M33.4434 18.2852L48.5644 31.4316"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M34.373 45.7959L48.5631 31.4318"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default ArrowRight;
