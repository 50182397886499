import type {IRenderByContentByTypeProps} from '@/components/pages/business-name-generator/SequencedContent/types';
import {SEQUENCED_CONTENT_TYPES} from '@/components/pages/business-name-generator/SequencedContent/types';
import Typography from '@/components/base/elements/Typography/Typography';

export const RenderContentByType = ({
  contentSectionData,
}: IRenderByContentByTypeProps) => {
  const {type, list, text} = contentSectionData;
  const isSubtitle = type === SEQUENCED_CONTENT_TYPES.SUBTITLE;

  return type === SEQUENCED_CONTENT_TYPES.LIST ? (
    <ul className="list-disc pl-8">
      {list &&
        list.map((listItem, index) => (
          <li key={index} className="text-body-base">
            {listItem}
          </li>
        ))}
    </ul>
  ) : (
    <Typography
      as={isSubtitle ? 'h3' : 'p'}
      size={isSubtitle ? 't5' : 'body-base'}
      className={isSubtitle ? 'mb-6 mt-10' : 'mb-6 last:mb-0'}
    >
      {text}
    </Typography>
  );
};
