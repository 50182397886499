import type {ReactNode} from 'react';

import {twMerge} from '@/stylesheets/twMerge';

import {MessageBubbleItem} from './MessageBubbleItem';
import {messageBubbleStyle} from './style';

interface MessageBubbleProps {
  messages: Array<string | ReactNode>;
  className?: string;
  ctaComponent?: ReactNode;
}

export const MessageBubble = ({
  messages,
  className,
  ctaComponent,
}: MessageBubbleProps) => (
  <div className={twMerge(messageBubbleStyle(), className)}>
    {messages &&
      messages.map((message, index) => (
        <MessageBubbleItem
          key={index}
          message={message}
          ctaComponent={ctaComponent}
        />
      ))}
  </div>
);
