import {useNavigation} from '@remix-run/react';
import cn from 'classnames';
import {useEffect, useRef, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

import {ProgressBar} from 'src/components/pages/business-name-generator/components/ProgressBar';
import FormSection from '@/components/pages/business-name-generator/components/FormSection/FormSection';
import Header from '@/components/pages/business-name-generator/components/Header/Header';
import {useTranslations} from '@/hooks/useTranslations';
import {containerStyles} from '@/components/pages/business-name-generator/components/Hero/style';
import type {
  BNGProps,
  responseProps,
} from '@/components/pages/business-name-generator/types';
import {isSearchMode} from '@/components/pages/business-name-generator/utils/utils';
import LoadMoreSection from '@/components/pages/business-name-generator/components/LoadMoreSection/LoadMoreSection';
import GeneratedNames from '@/components/pages/business-name-generator/components/GeneratedNames/GeneratedNames';
import IncentivesPromoBanner from '@/components/shared/IncentivesPromoBanner/IncentivesPromoBanner';

export default function BusinessNameGeneratorSection(props: BNGProps) {
  const {t, images} = useTranslations();
  const transition = useNavigation();
  const isLoadingResults = transition.state !== 'idle';
  const ref = useRef<null | HTMLDivElement>(null);
  const amountOfNameSections = props.state?.data?.length || 0;
  const noUniqValuesLeft = isEmpty(last(props.state?.data)?.generatedNames);
  const attributes = useMemo(
    () => ({
      ...props,
      ...t('generatedNames'),
      ...t('modal'),
      ...t('loadMore'),
      image: {src: images.modal, alt: t('modal.imageAlt')},
    }),
    [images.modal, props, t],
  );

  const reference = useMemo(() => <div id="reference" ref={ref}></div>, []);

  const resultSection = useMemo(() => {
    return (
      <>
        {props.state.data?.map((result: responseProps, index: number) => {
          if (isEmpty(result.generatedNames)) {
            return <div key={`${index}-result-block`}>{reference}</div>;
          }
          const fullAttrs = {...result, ...attributes};

          return (
            <div key={`${index}-result-block`}>
              {index === amountOfNameSections - 1 && reference}
              <GeneratedNames {...fullAttrs} index={index} />
            </div>
          );
        })}
        <LoadMoreSection
          noUniqValuesLeft={noUniqValuesLeft}
          state={props.state}
          setState={props.setState}
        />
      </>
    );
  }, [
    amountOfNameSections,
    attributes,
    noUniqValuesLeft,
    props.setState,
    props.state,
    reference,
  ]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({block: 'center'});
    }
  }, [transition.state, props]);

  return (
    <div className={cn(containerStyles())}>
      {isLoadingResults && <ProgressBar />}

      <div className="container">
        <IncentivesPromoBanner mode="light" className="pb-8 text-center" />

        <Header {...t('conversion')} mode={props.mode} />

        <FormSection {...props} isLoadingResults={isLoadingResults} />

        {isSearchMode(props.mode) && resultSection}
      </div>
    </div>
  );
}
