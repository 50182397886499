import type {VariantProps} from 'class-variance-authority';
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';

import {buttonStyles, spanStyles} from './style';

interface ButtonProps extends VariantProps<typeof buttonStyles> {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  filled?: boolean;
  gradientBorder?: boolean;
}

export default function Button({
  children,
  onClick,
  filled = false,
  gradientBorder = false,
  className,
}: ButtonProps) {
  return (
    <button
      className={twMerge(buttonStyles({filled, gradientBorder}), className)}
      onClick={onClick}
    >
      <span className={cn(!filled && spanStyles())}>{children}</span>
    </button>
  );
}
