import {cva} from 'class-variance-authority';

export const wrapButtonStyles = cva(
  [
    'rounded-[50px] p-[1px] cursor-pointer',
    'font-medium text-left',
    'bg-[#36244D] md:bg-lavender-10 w-full',
  ],
  {
    variants: {
      state: {
        default: ['group md:hover:bg-bng-results-button-gradient'],
        visited: ['bg-[#39336d] md:bg-[#39336d] hover:bg-[#39336d]'],
        error: ['md:bg-state-error-light xs:bg-state-error-light cursor-auto'],
      },
    },
  },
);

export const buttonStyles = cva(
  [
    'p-[16px] pr-[8px] bg-[#36244D] md:bg-bng-dark flex items-center justify-between rounded-[50px]',
    'cursor-default sm:cursor-pointer text-[18px] md:text-base md:leading-4 text-button-light-primary-text font-medium',
  ],
  {
    variants: {
      state: {
        default: [
          'md:group-hover:h-[46px] group-hover:border-bng-results-button-gradient',
          'border-lavender-10 h-12',
          'md:group-hover:m-[1px] md:group-hover:p-[15px] md:group-hover:pr-[7px]',
        ],
        visited: ['bg-[#39336d] md:bg-[#39336d] m-[1px] h-[46px]'],
        error: ['cursor-auto m-[1px] h-[46px]'],
      },
    },
  },
);

export const previewButtonStyles = cva(
  [
    'border-shade-30 rounded-[30px] transition h-9 pr-[7px]',
    'text-sm flex justify-end items-center font-courier text-lavender-10',
  ],
  {
    variants: {
      state: {
        default: [
          'md:group-hover:text-black md:w-26 xs:w-fit xs:pl-3.5 md:group-hover:bg-bng-results-button-preview-gradient',
        ],
        visited: ['text-button-light-primary-text md:w-26 xs:w-26'],
        error: ['hidden'],
      },
    },
  },
);

export const iconStyles = cva(['w-4 m-1 text-lavender-10'], {
  variants: {
    state: {
      default: ['md:group-hover:text-black'],
      visited: ['text-button-light-primary-text'],
      error: ['hidden'],
    },
  },
});
