import {useNavigation} from '@remix-run/react';
import type {VariantProps} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import {useTranslations} from '@/hooks/useTranslations';
import type {BNGmode} from '@/components/pages/business-name-generator/types';

import ArrowRight from '../../icons/ArrowRight';
import SparkleIcon from '../../icons/SparkleIcon';

import {buttonStyles, iconStyles, buttonTextStyles} from './style';

interface InputButtonProps extends VariantProps<typeof buttonStyles> {
  children?: React.ReactNode;
  className?: string;
  mode?: BNGmode;
  isValuePresent?: boolean;
  buttonText?: string | null;
  type: 'submit' | 'reset' | undefined;
  onClick?: () => void;
  disabled?: boolean;
  iconClassName?: string;
}

export default function InputButton({
  mode = 'dragonFruit',
  isValuePresent = false,
  buttonText,
  type,
  onClick,
  className,
  disabled = false,
  iconClassName,
}: InputButtonProps) {
  const {t} = useTranslations();

  const {state} = useNavigation();
  const isLoading = state !== 'idle';
  const text = isLoading ? t('conversion.input.buttonGenerating') : buttonText;
  const isTextVisible = isValuePresent && Boolean(buttonText);
  const isDisabled = isLoading || disabled;

  return (
    <button
      type={type}
      onClick={onClick}
      className={twMerge(buttonStyles({mode, isLoading}), className)}
      aria-hidden="true"
      disabled={isDisabled}
    >
      <span className={buttonTextStyles({mode, isTextVisible})}>
        <SparkleIcon className="mr-[10px] fill-white" />
        {text}
      </span>

      <ArrowRight className={twMerge(iconStyles({mode}), iconClassName)} />
    </button>
  );
}
