import Section from '@/components/base/layouts/Section/Section';
import Typography from '@/components/base/elements/Typography/Typography';
import {twMerge} from '@/stylesheets/twMerge';
import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import Button from '@/components/pages/business-name-generator/components/Button/Button';

import {errorTextStyle, errorBotStyle} from './style';

interface ErrorSectionProps {
  textLine1: string;
  textLine2: string;
  className: string;
  image: ImageProps;
  buttonText: string;
}

export default function ErrorSection({
  textLine1,
  textLine2,
  className,
  image,
  buttonText,
}: ErrorSectionProps) {
  return (
    <Section
      className={twMerge('pt-0 pb-0', className)}
      sectionName="errorPage"
    >
      <div className="justify-center flex flex-column md:flex-row min-w-fit flex-wrap xs:p-2 sm:p-0">
        <Image {...image} className={twMerge(errorBotStyle(), className)} />
        <div>
          <Typography className={twMerge(errorTextStyle(), className)}>
            {textLine1}
          </Typography>
          <Typography className={twMerge(errorTextStyle(), 'md:pt-0')}>
            {textLine2}
          </Typography>
          <Button
            data-component-name="try-again"
            onClick={() => {
              window.location.reload();
            }}
            className="md:flex inline-flex"
          >
            <span>{buttonText}</span>
          </Button>
        </div>
      </div>
    </Section>
  );
}
